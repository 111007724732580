<template>
  <div
    v-show="showModal"
    class="layer-weekly-detail"
  >
    <div
      ref="wContainer"
      class="medical-detail-wrap"
    >
      <div class="layer-utility">
        <button
          class="btn-close"
          @click.prevent="close"
        >
          close
        </button>
        <button
          class="btn-reply"
          @click.prevent="moveReply"
        >
          reply
        </button>
      </div>

      <div class="md-header">
        <dl>
          <dt style="font-size: 24px;">
            {{ title }}
          </dt>
          <dd>{{ startDate | $dateFormatter }}{{ startDate ? ' ~ ' : '' }}{{ endDate | $dateFormatter }}</dd>
        </dl>
      </div>

      <div class="md-contents">
        <div class="survey-layer-area">
          <div class="survey-list">
            <div
              v-for="(q, idx) in elements"
              :key="q.id"
              class="survey-item"
            >
              <dl>
                <dt>
                  <span
                    style="margin-right: 4px; font-size: 16px;"
                  >
                    {{ (idx + 1) }}.
                  </span>
                  <span
                    style="font-size: 16px;"
                  >
                    {{ q.title }}
                  </span>
                </dt>
                <dd>
                  <ul>
                    <li
                      v-for="m in q.pollElementDetails"
                      :key="m.id"
                    >
                      <template v-if="isResultReport">
                        <div class="sur-radio-wrap">
                          <input
                            :id="`answer-${m.id}`"
                            v-model="q.answerValue"
                            type="radio"
                            :name="`que-${q.id}`"
                            :value="m.id"
                            disabled
                          >
                          <label :for="`answer-${m.id}`">{{ m.subText }}</label>
                        </div>
                        <div
                          class="lange-result"
                          :class="{ 'checkbox-lange': q.answerValue === m.id }"
                        >
                          <div
                            v-if="m.choiceCount"
                            class="lange-wrap"
                            :style="{ width: `${m.choiceRate}%`, background: (q.answerValue === m.id ? '#C3D3FB' : '#DDDDDD') }"
                          />
                          <div
                            v-if="m.choiceCount"
                            class="label-text"
                          >
                            {{ m.choiceCount }}표 / {{ m.choiceRate }}%
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="sur-radio-wrap">
                          <input
                            :id="`answer-${m.id}`"
                            v-model="q.answerValue"
                            type="radio"
                            :name="`que-${q.id}`"
                            :value="m.id"
                          >
                          <label :for="`answer-${m.id}`">{{ m.subText }}</label>
                        </div>
                      </template>
                    </li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>

          <div class="btn-wrap">
            <button
              v-if="isResultReport === false"
              id="submit2"
              class="btn-sendIn"
              @click.prevent="submit"
            >
              제출하기
            </button>
          </div>
        </div>
      </div>

      <div class="survey-reply-ad">
        <p class="b-txt">
          해당 Weekly Choice에 자유롭게 의견을 남기시고 공감을 해주세요.
        </p>
        <p class="s-txt">
          게시물과 관계 없는 댓글의 경우, 관리자가 댓글창의 노출을 제한할 수 있습니다.
        </p>
      </div>
      <comment-form
        ref="commentForm"
        :content-type="4"
      />

    </div>
    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { confirmPointMission } from '@/common/userInfo/service'
import CommentForm from '../CommentForm.vue'

export default {
  components: {
    CommentForm,
  },

  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },

    showModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      title: null,
      keyword: null,
      startDate: null,
      endDate: null,
      elements: [],
      contentType: 4, // 4: Weekly Choice
      isSubmit: false,
      isResultReport: null,
      isProcessing: false,
    }
  },

  computed: {
    ...mapGetters({
      medicalDept: 'infoData/getMedicalDept',
    }),
  },

  watch: {
    id(to) {
      if (to) {
        this.fetchWeeklyChoice(to)
      }
    },
  },

  methods: {
    close() {
      this.title = null
      this.keyword = null
      this.startDate = null
      this.endDate = null
      this.elements = []
      this.isResultReport = null

      this.$emit('close', this.isSubmit)

      this.isSubmit = false
    },

    moveReply() {
      // 스크롤 위치를 댓글 영역으로 이동
      this.$refs.commentForm.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    },

    fetchWeeklyChoice(id) {
      axios.get(`/fu/survey/weekly-choice/${id}`)
        .then(rs => {
          this.title = rs.data.title
          this.keyword = rs.data.keyword
          this.startDate = rs.data.startDate
          this.endDate = rs.data.endDate
          this.isResultReport = (rs.data.isAnswer || rs.data.isFinish)

          // const { isAnswer } = rs.data
          const { answerSummary } = rs.data
          const elements = rs.data.pollElements

          if (this.isResultReport) {
            for (let i = 0; i < elements.length; i += 1) {
              let maxChoiceCount = 0
              const details = elements[i].pollElementDetails

              for (let j = 0; j < details.length; j += 1) {
                const answer = answerSummary.find(x => x.pollElementDetailId === details[j].id)
                if (answer) {
                  details[j].choiceCount = answer.choiceCount || 0
                  details[j].choiceRate = ((answer.choiceCount / answer.totalCount) * 100).toFixed(1)
                  if (maxChoiceCount < details[j].choiceCount) {
                    maxChoiceCount = details[j].choiceCount
                  }
                  if (answer.myAnswer) {
                    elements[i].answerValue = answer.pollElementDetailId
                  }
                }
              }
              elements[i].maxChoiceCount = maxChoiceCount
            }
          } else {
            for (let i = 0; i < elements.length; i += 1) {
              elements[i].answerValue = null
            }
          }
          this.elements = elements

          // 컨텐츠 로딩 후 스크롤을 맨 위로 고정
          this.$refs.wContainer.scrollTo(0, 0)

          // 댓글 불러오기
          this.$refs.commentForm.fetchComment(this.id, this.contentType)
        })
        .catch(() => {
          this.showAlertDialog('Weekly Choice 내용을 불러오는데 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },

    submit() {
      if (this.isProcessing) {
        return
      }

      const answers = this.elements.map(x => ({ pollElementId: x.id, pollElementDetailId: x.answerValue }))

      const isValid = answers.every(x => x.pollElementDetailId)
      if (!isValid) {
        this.showAlertDialog('답변을 완료하지 않은 문항이 있습니다.')
        return
      }

      this.isProcessing = true

      axios.post('/fu/survey/weekly-choice/answer', {
        pollId: this.id,
        medicalDept: this.medicalDept,
        pollElementAnswers: answers,
      })
        .then(() => {
          this.isSubmit = true

          this.showAlertDialog('Weekly Choice 답변을 등록하였습니다.', () => {
            this.isProcessing = false
            // 결과 확인을 위해 재 조회
            this.fetchWeeklyChoice(this.id)
          })

          // 이달의 미션 수행 체크
          confirmPointMission(16)
        })
        .catch(() => {
          this.isProcessing = false

          this.showAlertDialog('Weekly Choice 답변 저장에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
  },
}
</script>
