import dayjs from 'dayjs'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

export const arrayJoinStr = (array, property, delimiter = ',') => {
  if (!array || !property) return null

  return array.map(m => m[property]).join(delimiter)
}

export const numberFormatter = value => {
  const rtnValue = ` ${value}`
  return String(rtnValue).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const dateFormatter = (value, formatting = 'YYYY-MM-DD') => dayjs(new Date(value)).format(formatting)

export const errorFormatter = (error, defaultMessage) => {
  if (!error || !error.response || !error.response.data || error.response.status === 500) {
    return defaultMessage
  }

  const { data } = error.response

  if (typeof data === 'string') {
    return data
  }
  if (data.error && typeof data.error === 'string') {
    return data.error
  }
  if (data.errors && typeof data.errors === 'object') {
    let rtnValue = ''
    Object.keys(data.errors).forEach(key => {
      rtnValue += `${data.errors[key][0]}\n`
    })
    return rtnValue
  }
  return defaultMessage
}
