var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.options.show
      ? _c(
          "div",
          {
            staticClass:
              "layer-popup-alert survey-progress-type survey-completed",
            staticStyle: { "z-index": "1000", position: "fixed" },
          },
          [
            _c(
              "p",
              { staticClass: "txt" },
              [
                _vm._l(_vm.splitMessages, function (msg, index) {
                  return [
                    _vm._v(" " + _vm._s(msg) + " "),
                    _c("br", { key: index }),
                  ]
                }),
              ],
              2
            ),
            _c(
              "p",
              { staticClass: "img-banner", staticStyle: { cursor: "pointer" } },
              [
                _c("img", {
                  attrs: { src: _vm.options.bannerPath },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.clickBanner($event)
                    },
                  },
                }),
              ]
            ),
            _c("div", { staticClass: "flex-c" }, [
              _c(
                "button",
                {
                  staticClass: "btn-ok",
                  on: {
                    click: function ($event) {
                      return _vm.submit(true)
                    },
                  },
                },
                [_vm._v(" 확인 ")]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }