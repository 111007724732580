import store from '@/store'
import axios from '@axios'

// eslint-disable-next-line import/prefer-default-export
export const setError = async (message, path) => {
  const yid = store.getters['infoData/getShaYoyangNum']
  const tid = store.getters['infoData/getShaEnLicenseNum']

  axios.post('/fu/error/log', {
    ProjectType: 3, // FrontUserWeb
    Path: path,
    ErrorMessage: message,
    StackTrace: null,
    AppVersion: null,
    YID: yid,
    TID: tid,
    Description: null,
    PCID: '',
  })
    .then(() => {
    })
    .catch(() => {
    })
}

// eslint-disable-next-line import/prefer-default-export
export const setException = async (errorObj, path, extMessage = null) => {
  const yid = store.getters['infoData/getShaYoyangNum']
  const tid = store.getters['infoData/getShaEnLicenseNum']

  let lineNumberStr = null
  if (errorObj.stack) {
    const match = errorObj.stack.match(/at .* \(.*:(\d+):(\d+)\)/) // 스택 트레이스에 라인번호 추출
    if (match) {
      lineNumberStr = `lineNumber: ${match[1]}`
    }
  }

  axios.post('/fu/error/log', {
    ProjectType: 3, // FrontUserWeb
    Path: `${path} ${lineNumberStr}`,
    ErrorMessage: `${errorObj.message} ${extMessage}`,
    StackTrace: errorObj.stack,
    AppVersion: null,
    YID: yid,
    TID: tid,
    Description: null,
    PCID: '',
  })
    .then(() => {
    })
    .catch(() => {
    })
}
