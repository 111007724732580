var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "survey-reward-layer-area" }, [
    _c(
      "div",
      { staticClass: "csp-contents", staticStyle: { "padding-bottom": "0px" } },
      [
        _c("p", { staticClass: "s-txt" }, [
          _vm._v(" 닥터인포 설문조사에 참여해 주셔서 감사합니다. "),
        ]),
        _vm._m(0),
        _vm._m(1),
        _c("div", { staticClass: "items-div2" }, [
          _c(
            "div",
            {
              staticClass: "item-point",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.pointRewardPreProcess($event)
                },
              },
            },
            [_vm._m(2)]
          ),
          _c(
            "div",
            {
              staticClass: "item-gifticon",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.giftRewardPreProcess($event)
                },
              },
            },
            [_c("img", { attrs: { src: _vm.goodsImgUrl } })]
          ),
        ]),
        _c("reward-privacy-confirm", {
          attrs: { "is-show": _vm.showPrivacyConfirmPopup },
          on: { close: _vm.closePrivacyConfirmPopup },
        }),
        _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
        _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "b-txt" }, [
      _vm._v(" 참여 보상을 선택해 주세요"),
      _c("br"),
      _c("span", [_vm._v("(포인트 or 기프티콘)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "txt-blue" }, [
      _vm._v("*닥터인포 포인트는 회원 전용 혜택으로,"),
      _c("br"),
      _vm._v(
        " 적립한 포인트는 포인트몰에서 다양한 상품권으로 교환할 수 있습니다. "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", [
      _c("dt", [_vm._v("닥터인포")]),
      _c("dd", [_vm._v("포인트 받기"), _c("i", { staticClass: "icon-arrow" })]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }