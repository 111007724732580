var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showModal,
          expression: "showModal",
        },
      ],
      staticClass: "layer-survey-detail",
    },
    [
      _c("div", { staticClass: "medical-detail-wrap" }, [
        _c("div", { staticClass: "layer-utility" }, [
          _c(
            "button",
            {
              staticClass: "btn-close",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.beforeClose($event)
                },
              },
            },
            [_vm._v(" close ")]
          ),
        ]),
        _c("div", { staticClass: "md-header survey-layer-type" }, [
          _c("dl", [
            _c("dt", { staticStyle: { "font-size": "24px" } }, [
              _vm._v(" " + _vm._s(_vm.title) + " "),
            ]),
            _c("dd", [
              _vm._v(
                _vm._s(_vm._f("$dateFormatter")(_vm.startDate)) +
                  _vm._s(_vm.startDate ? " ~ " : "") +
                  _vm._s(_vm._f("$dateFormatter")(_vm.endDate))
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "md-contents" },
          [
            !_vm.isRewardPage
              ? _c("div", { staticClass: "survey-layer-area" }, [
                  _c("div", {
                    directives: [
                      {
                        name: "dompurify-html",
                        rawName: "v-dompurify-html",
                        value: _vm.currPage ? _vm.currPage.description : "",
                        expression: "currPage ? currPage.description : ''",
                      },
                    ],
                    staticClass: "survey-welcome-info",
                  }),
                  _c(
                    "div",
                    { staticClass: "survey-list" },
                    [
                      _vm._l(_vm.elements, function (q) {
                        return [
                          q.isShow
                            ? _c(
                                "div",
                                {
                                  key: "el" + q.id,
                                  ref: "el" + q.id,
                                  refInFor: true,
                                  class:
                                    "survey-item " +
                                    (q.groupStyle
                                      ? q.groupStyle + "-question"
                                      : ""),
                                },
                                [
                                  _c("dl", [
                                    _c("dt", [
                                      _c(
                                        "div",
                                        {
                                          class:
                                            "" +
                                            (q.groupStyle === "sub"
                                              ? "connection-questions mgt0 essential-type"
                                              : ""),
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: q.isRequired,
                                                  expression: "q.isRequired",
                                                },
                                              ],
                                              staticClass: "essential-label",
                                            },
                                            [_vm._v("필수")]
                                          ),
                                          _c("p", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  q.groupStyle === "sub"
                                                    ? ""
                                                    : q.dispSeq + "."
                                                )
                                              ),
                                            ]),
                                            _vm._v(_vm._s(q.title)),
                                          ]),
                                          q.groupStyle === "sub" &&
                                          q.description
                                            ? _c("div", {
                                                directives: [
                                                  {
                                                    name: "dompurify-html",
                                                    rawName: "v-dompurify-html",
                                                    value: q.description,
                                                    expression: "q.description",
                                                  },
                                                ],
                                                staticClass: "s-txt",
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                    q.groupStyle !== "sub" && q.description
                                      ? _c("dd", [
                                          _c("div", {
                                            directives: [
                                              {
                                                name: "dompurify-html",
                                                rawName: "v-dompurify-html",
                                                value: q.description,
                                                expression: "q.description",
                                              },
                                            ],
                                            staticClass: "s-txt",
                                          }),
                                        ])
                                      : _vm._e(),
                                    q.type !== 50
                                      ? _c(
                                          "dd",
                                          [
                                            q.type === 1 ||
                                            (q.type === 9 && q.subType === 1)
                                              ? [
                                                  _c(
                                                    "ul",
                                                    [
                                                      _vm._l(
                                                        _vm.shuffle(
                                                          q.choices,
                                                          q.isRandomOrder
                                                        ),
                                                        function (ch) {
                                                          return [
                                                            _c(
                                                              "li",
                                                              {
                                                                key:
                                                                  "sq" +
                                                                  q.seq +
                                                                  "-ch" +
                                                                  ch.id,
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "sur-radio-wrap",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                q.answer,
                                                                              expression:
                                                                                "q.answer",
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          id:
                                                                            "sq" +
                                                                            q.seq +
                                                                            "-ch" +
                                                                            ch.id,
                                                                          name:
                                                                            "el" +
                                                                            q.id,
                                                                          type: "radio",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              ch.id,
                                                                            checked:
                                                                              _vm._q(
                                                                                q.answer,
                                                                                ch.id
                                                                              ),
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            [
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  q,
                                                                                  "answer",
                                                                                  ch.id
                                                                                )
                                                                              },
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.changeAnswer(
                                                                                  q
                                                                                )
                                                                              },
                                                                            ],
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for:
                                                                            "sq" +
                                                                            q.seq +
                                                                            "-ch" +
                                                                            ch.id,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            ch.description
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            ch.isEtc
                                                              ? _c(
                                                                  "li",
                                                                  {
                                                                    key:
                                                                      "sq" +
                                                                      q.seq +
                                                                      "-ch-other" +
                                                                      ch.id,
                                                                    staticClass:
                                                                      "mgl28",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-wrap",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    q.answerEtcText,
                                                                                  expression:
                                                                                    "q.answerEtcText",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                type: "text",
                                                                                disabled:
                                                                                  q.answer !==
                                                                                  ch.id,
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  q.answerEtcText,
                                                                              },
                                                                            on: {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  ) {
                                                                                    return
                                                                                  }
                                                                                  _vm.$set(
                                                                                    q,
                                                                                    "answerEtcText",
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              : _vm._e(),
                                            q.type === 2 ||
                                            (q.type === 9 && q.subType === 2)
                                              ? [
                                                  _c(
                                                    "ul",
                                                    [
                                                      _vm._l(
                                                        _vm.shuffle(
                                                          q.choices,
                                                          q.isRandomOrder
                                                        ),
                                                        function (ch) {
                                                          return [
                                                            _c(
                                                              "li",
                                                              {
                                                                key:
                                                                  "sq" +
                                                                  q.seq +
                                                                  "-ch" +
                                                                  ch.id,
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "sur-checkbox-wrap",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                q.answer,
                                                                              expression:
                                                                                "q.answer",
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          id:
                                                                            "sq" +
                                                                            q.seq +
                                                                            "-ch" +
                                                                            ch.id,
                                                                          type: "checkbox",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              ch.id,
                                                                            checked:
                                                                              Array.isArray(
                                                                                q.answer
                                                                              )
                                                                                ? _vm._i(
                                                                                    q.answer,
                                                                                    ch.id
                                                                                  ) >
                                                                                  -1
                                                                                : q.answer,
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            [
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                var $$a =
                                                                                    q.answer,
                                                                                  $$el =
                                                                                    $event.target,
                                                                                  $$c =
                                                                                    $$el.checked
                                                                                      ? true
                                                                                      : false
                                                                                if (
                                                                                  Array.isArray(
                                                                                    $$a
                                                                                  )
                                                                                ) {
                                                                                  var $$v =
                                                                                      ch.id,
                                                                                    $$i =
                                                                                      _vm._i(
                                                                                        $$a,
                                                                                        $$v
                                                                                      )
                                                                                  if (
                                                                                    $$el.checked
                                                                                  ) {
                                                                                    $$i <
                                                                                      0 &&
                                                                                      _vm.$set(
                                                                                        q,
                                                                                        "answer",
                                                                                        $$a.concat(
                                                                                          [
                                                                                            $$v,
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                  } else {
                                                                                    $$i >
                                                                                      -1 &&
                                                                                      _vm.$set(
                                                                                        q,
                                                                                        "answer",
                                                                                        $$a
                                                                                          .slice(
                                                                                            0,
                                                                                            $$i
                                                                                          )
                                                                                          .concat(
                                                                                            $$a.slice(
                                                                                              $$i +
                                                                                                1
                                                                                            )
                                                                                          )
                                                                                      )
                                                                                  }
                                                                                } else {
                                                                                  _vm.$set(
                                                                                    q,
                                                                                    "answer",
                                                                                    $$c
                                                                                  )
                                                                                }
                                                                              },
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.changeAnswer(
                                                                                  q
                                                                                )
                                                                              },
                                                                            ],
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for:
                                                                            "sq" +
                                                                            q.seq +
                                                                            "-ch" +
                                                                            ch.id,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            ch.description
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            ch.isEtc
                                                              ? _c(
                                                                  "li",
                                                                  {
                                                                    key:
                                                                      "sq" +
                                                                      q.seq +
                                                                      "-ch-other" +
                                                                      ch.id,
                                                                    staticClass:
                                                                      "mgl28",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-wrap",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    q.answerEtcText,
                                                                                  expression:
                                                                                    "q.answerEtcText",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                type: "text",
                                                                                disabled:
                                                                                  q.answer.indexOf(
                                                                                    ch.id
                                                                                  ) ===
                                                                                  -1,
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  q.answerEtcText,
                                                                              },
                                                                            on: {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  ) {
                                                                                    return
                                                                                  }
                                                                                  _vm.$set(
                                                                                    q,
                                                                                    "answerEtcText",
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              : _vm._e(),
                                            q.type === 3
                                              ? [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "text-wrap",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: q.answer,
                                                            expression:
                                                              "q.answer",
                                                          },
                                                        ],
                                                        domProps: {
                                                          value: q.answer,
                                                        },
                                                        on: {
                                                          input: [
                                                            function ($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                q,
                                                                "answer",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.filteredValue(
                                                                q
                                                              )
                                                            },
                                                          ],
                                                          change: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.changeAnswer(
                                                              q
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                            q.type === 4
                                              ? [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "textarea-wrap",
                                                    },
                                                    [
                                                      _c("textarea", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: q.answer,
                                                            expression:
                                                              "q.answer",
                                                          },
                                                        ],
                                                        attrs: { rows: q.rows },
                                                        domProps: {
                                                          value: q.answer,
                                                        },
                                                        on: {
                                                          input: [
                                                            function ($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                q,
                                                                "answer",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.filteredValue(
                                                                q
                                                              )
                                                            },
                                                          ],
                                                          change: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.changeAnswer(
                                                              q
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                            q.type === 5 ||
                                            (q.type === 9 && q.subType === 3)
                                              ? [
                                                  _c(
                                                    "ul",
                                                    [
                                                      _vm._l(
                                                        _vm.shuffle(
                                                          q.choices,
                                                          q.isRandomOrder
                                                        ),
                                                        function (ch) {
                                                          return [
                                                            _c(
                                                              "li",
                                                              {
                                                                key:
                                                                  "sq" +
                                                                  q.seq +
                                                                  "-ch" +
                                                                  ch.id,
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btnRanking",
                                                                    class: {
                                                                      active:
                                                                        ch.rank,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.clickRank(
                                                                            q,
                                                                            ch.id
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "ranking",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "em",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                ch.rank
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        ch.description
                                                                      ) + " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            ch.isEtc
                                                              ? _c(
                                                                  "li",
                                                                  {
                                                                    key:
                                                                      "sq" +
                                                                      q.seq +
                                                                      "-ch-other" +
                                                                      ch.id,
                                                                    staticClass:
                                                                      "mgl28",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-wrap",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    q.answerEtcText,
                                                                                  expression:
                                                                                    "q.answerEtcText",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                type: "text",
                                                                                disabled:
                                                                                  q.answer.indexOf(
                                                                                    ch.id
                                                                                  ) ===
                                                                                  -1,
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  q.answerEtcText,
                                                                              },
                                                                            on: {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  ) {
                                                                                    return
                                                                                  }
                                                                                  _vm.$set(
                                                                                    q,
                                                                                    "answerEtcText",
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              : _vm._e(),
                                            q.type === 6
                                              ? [
                                                  _vm._l(
                                                    q.choices,
                                                    function (ch) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            key: "ch" + ch.id,
                                                            staticClass:
                                                              "specific-gravity-type",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectInput(
                                                                  q.type +
                                                                    "_" +
                                                                    q.id +
                                                                    "_" +
                                                                    ch.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "sg-item",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "title",
                                                                  },
                                                                  [
                                                                    _c("em", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          ch.seq +
                                                                            ". " +
                                                                            ch.description
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "sg-write-input",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model.number",
                                                                              value:
                                                                                q
                                                                                  .answer[
                                                                                  ch.seq -
                                                                                    1
                                                                                ],
                                                                              expression:
                                                                                "q.answer[ch.seq-1]",
                                                                              modifiers:
                                                                                {
                                                                                  number: true,
                                                                                },
                                                                            },
                                                                          ],
                                                                        class:
                                                                          "" +
                                                                          (q
                                                                            .answer[
                                                                            ch.seq -
                                                                              1
                                                                          ] > 0
                                                                            ? "haveValue"
                                                                            : ""),
                                                                        attrs: {
                                                                          id:
                                                                            q.type +
                                                                            "_" +
                                                                            q.id +
                                                                            "_" +
                                                                            ch.id,
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              q
                                                                                .answer[
                                                                                ch.seq -
                                                                                  1
                                                                              ],
                                                                          },
                                                                        on: {
                                                                          input:
                                                                            [
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                ) {
                                                                                  return
                                                                                }
                                                                                _vm.$set(
                                                                                  q.answer,
                                                                                  ch.seq -
                                                                                    1,
                                                                                  _vm._n(
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                )
                                                                              },
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.filteredValue(
                                                                                  q
                                                                                )
                                                                              },
                                                                            ],
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.changeAnswer(
                                                                                q
                                                                              )
                                                                            },
                                                                          blur: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$forceUpdate()
                                                                          },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "%"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _c("div", {
                                                                  staticClass:
                                                                    "bar-range",
                                                                  style:
                                                                    "width: " +
                                                                    Math.min(
                                                                      (q.answer[
                                                                        ch.seq -
                                                                          1
                                                                      ] /
                                                                        q.sumCheck) *
                                                                        100,
                                                                      100
                                                                    ) +
                                                                    "%",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn-sg-wide",
                                                      attrs: {
                                                        disabled:
                                                          q.answer.reduce(
                                                            function (
                                                              acc,
                                                              val
                                                            ) {
                                                              return acc + val
                                                            },
                                                            0
                                                          ) !== q.sumCheck,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " 현재 입력 비율 합계"
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            q.answer.reduce(
                                                              function (
                                                                acc,
                                                                val
                                                              ) {
                                                                return acc + val
                                                              },
                                                              0
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v("% "),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                            q.type === 7 && q.subType === 1
                                              ? [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grid-table-type",
                                                    },
                                                    [
                                                      _c("table", [
                                                        _c(
                                                          "colgroup",
                                                          [
                                                            _c("col", {
                                                              staticStyle: {
                                                                width: "162px",
                                                              },
                                                            }),
                                                            _vm._l(
                                                              q.choices,
                                                              function (chCol) {
                                                                return [
                                                                  chCol.isColumn
                                                                    ? [
                                                                        _c(
                                                                          "col",
                                                                          {
                                                                            key:
                                                                              "chCol" +
                                                                              chCol.id,
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "auto",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                        _c("thead", [
                                                          _c(
                                                            "tr",
                                                            [
                                                              _c("th"),
                                                              _vm._l(
                                                                q.choices,
                                                                function (
                                                                  chCol
                                                                ) {
                                                                  return [
                                                                    chCol.isColumn
                                                                      ? [
                                                                          _c(
                                                                            "th",
                                                                            {
                                                                              key:
                                                                                "chCol" +
                                                                                chCol.id,
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    chCol.description
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]),
                                                        _c(
                                                          "tbody",
                                                          [
                                                            _vm._l(
                                                              q.choices,
                                                              function (chRow) {
                                                                return [
                                                                  !chRow.isColumn
                                                                    ? [
                                                                        _c(
                                                                          "tr",
                                                                          {
                                                                            key:
                                                                              "chRow" +
                                                                              chRow.id,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "th",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    chRow.description
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._l(
                                                                              q.choices,
                                                                              function (
                                                                                chCol
                                                                              ) {
                                                                                return [
                                                                                  chCol.isColumn
                                                                                    ? [
                                                                                        _c(
                                                                                          "td",
                                                                                          {
                                                                                            key:
                                                                                              "chRow" +
                                                                                              chRow.id +
                                                                                              "-chCol" +
                                                                                              chCol.id,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "sur-radio-noneLabel",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "input",
                                                                                                  {
                                                                                                    directives:
                                                                                                      [
                                                                                                        {
                                                                                                          name: "model",
                                                                                                          rawName:
                                                                                                            "v-model",
                                                                                                          value:
                                                                                                            q
                                                                                                              .answer[
                                                                                                              chRow.seq -
                                                                                                                1
                                                                                                            ],
                                                                                                          expression:
                                                                                                            "q.answer[chRow.seq-1]",
                                                                                                        },
                                                                                                      ],
                                                                                                    attrs:
                                                                                                      {
                                                                                                        id:
                                                                                                          "radioType-" +
                                                                                                          chRow.id +
                                                                                                          "-" +
                                                                                                          chCol.id,
                                                                                                        type: "radio",
                                                                                                        name:
                                                                                                          "type-" +
                                                                                                          chRow.id,
                                                                                                      },
                                                                                                    domProps:
                                                                                                      {
                                                                                                        value:
                                                                                                          chCol.seq,
                                                                                                        checked:
                                                                                                          _vm._q(
                                                                                                            q
                                                                                                              .answer[
                                                                                                              chRow.seq -
                                                                                                                1
                                                                                                            ],
                                                                                                            chCol.seq
                                                                                                          ),
                                                                                                      },
                                                                                                    on: {
                                                                                                      input:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          return _vm.filteredValue(
                                                                                                            q
                                                                                                          )
                                                                                                        },
                                                                                                      change:
                                                                                                        [
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.$set(
                                                                                                              q.answer,
                                                                                                              chRow.seq -
                                                                                                                1,
                                                                                                              chCol.seq
                                                                                                            )
                                                                                                          },
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.preventDefault()
                                                                                                            return _vm.changeAnswer(
                                                                                                              q
                                                                                                            )
                                                                                                          },
                                                                                                        ],
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                                _c(
                                                                                                  "label",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        for:
                                                                                                          "radioType-" +
                                                                                                          chRow.id +
                                                                                                          "-" +
                                                                                                          chCol.id,
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "라벨" +
                                                                                                        _vm._s(
                                                                                                          chRow.id +
                                                                                                            "-" +
                                                                                                            chCol.id
                                                                                                        )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    : _vm._e(),
                                                                                ]
                                                                              }
                                                                            ),
                                                                          ],
                                                                          2
                                                                        ),
                                                                      ]
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                            q.type === 7 && q.subType === 2
                                              ? [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grid-table-type",
                                                    },
                                                    [
                                                      _c("table", [
                                                        _c(
                                                          "colgroup",
                                                          [
                                                            _c("col", {
                                                              staticStyle: {
                                                                width: "162px",
                                                              },
                                                            }),
                                                            _vm._l(
                                                              q.choices,
                                                              function (chCol) {
                                                                return [
                                                                  chCol.isColumn
                                                                    ? [
                                                                        _c(
                                                                          "col",
                                                                          {
                                                                            key:
                                                                              "chCol" +
                                                                              chCol.id,
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "auto",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                        _c("thead", [
                                                          _c(
                                                            "tr",
                                                            [
                                                              _c("th"),
                                                              _vm._l(
                                                                q.choices,
                                                                function (
                                                                  chCol
                                                                ) {
                                                                  return [
                                                                    chCol.isColumn
                                                                      ? [
                                                                          _c(
                                                                            "th",
                                                                            {
                                                                              key:
                                                                                "chCol" +
                                                                                chCol.id,
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    chCol.description
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]),
                                                        _c(
                                                          "tbody",
                                                          [
                                                            _vm._l(
                                                              q.choices,
                                                              function (chRow) {
                                                                return [
                                                                  !chRow.isColumn
                                                                    ? [
                                                                        _c(
                                                                          "tr",
                                                                          {
                                                                            key:
                                                                              "chRow" +
                                                                              chRow.id,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "th",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    chRow.description
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._l(
                                                                              q.choices,
                                                                              function (
                                                                                chCol
                                                                              ) {
                                                                                return [
                                                                                  chCol.isColumn
                                                                                    ? [
                                                                                        _c(
                                                                                          "td",
                                                                                          {
                                                                                            key:
                                                                                              "chRow" +
                                                                                              chRow.id +
                                                                                              "-chCol" +
                                                                                              chCol.id,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "survey-radio-noneLabel",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "input",
                                                                                                  {
                                                                                                    directives:
                                                                                                      [
                                                                                                        {
                                                                                                          name: "model",
                                                                                                          rawName:
                                                                                                            "v-model",
                                                                                                          value:
                                                                                                            q
                                                                                                              .answer[
                                                                                                              chRow.seq -
                                                                                                                1
                                                                                                            ][
                                                                                                              chCol.seq -
                                                                                                                1
                                                                                                            ],
                                                                                                          expression:
                                                                                                            "q.answer[chRow.seq-1][chCol.seq-1]",
                                                                                                        },
                                                                                                      ],
                                                                                                    attrs:
                                                                                                      {
                                                                                                        id:
                                                                                                          "checkboxType-" +
                                                                                                          chRow.id +
                                                                                                          "-" +
                                                                                                          chCol.id,
                                                                                                        type: "checkbox",
                                                                                                        name:
                                                                                                          "type-" +
                                                                                                          chRow.id +
                                                                                                          "-" +
                                                                                                          chCol.id,
                                                                                                      },
                                                                                                    domProps:
                                                                                                      {
                                                                                                        checked:
                                                                                                          Array.isArray(
                                                                                                            q
                                                                                                              .answer[
                                                                                                              chRow.seq -
                                                                                                                1
                                                                                                            ][
                                                                                                              chCol.seq -
                                                                                                                1
                                                                                                            ]
                                                                                                          )
                                                                                                            ? _vm._i(
                                                                                                                q
                                                                                                                  .answer[
                                                                                                                  chRow.seq -
                                                                                                                    1
                                                                                                                ][
                                                                                                                  chCol.seq -
                                                                                                                    1
                                                                                                                ],
                                                                                                                null
                                                                                                              ) >
                                                                                                              -1
                                                                                                            : q
                                                                                                                .answer[
                                                                                                                chRow.seq -
                                                                                                                  1
                                                                                                              ][
                                                                                                                chCol.seq -
                                                                                                                  1
                                                                                                              ],
                                                                                                      },
                                                                                                    on: {
                                                                                                      input:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          return _vm.filteredValue(
                                                                                                            q
                                                                                                          )
                                                                                                        },
                                                                                                      change:
                                                                                                        [
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            var $$a =
                                                                                                                q
                                                                                                                  .answer[
                                                                                                                  chRow.seq -
                                                                                                                    1
                                                                                                                ][
                                                                                                                  chCol.seq -
                                                                                                                    1
                                                                                                                ],
                                                                                                              $$el =
                                                                                                                $event.target,
                                                                                                              $$c =
                                                                                                                $$el.checked
                                                                                                                  ? true
                                                                                                                  : false
                                                                                                            if (
                                                                                                              Array.isArray(
                                                                                                                $$a
                                                                                                              )
                                                                                                            ) {
                                                                                                              var $$v =
                                                                                                                  null,
                                                                                                                $$i =
                                                                                                                  _vm._i(
                                                                                                                    $$a,
                                                                                                                    $$v
                                                                                                                  )
                                                                                                              if (
                                                                                                                $$el.checked
                                                                                                              ) {
                                                                                                                $$i <
                                                                                                                  0 &&
                                                                                                                  _vm.$set(
                                                                                                                    q
                                                                                                                      .answer[
                                                                                                                      chRow.seq -
                                                                                                                        1
                                                                                                                    ],
                                                                                                                    chCol.seq -
                                                                                                                      1,
                                                                                                                    $$a.concat(
                                                                                                                      [
                                                                                                                        $$v,
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  )
                                                                                                              } else {
                                                                                                                $$i >
                                                                                                                  -1 &&
                                                                                                                  _vm.$set(
                                                                                                                    q
                                                                                                                      .answer[
                                                                                                                      chRow.seq -
                                                                                                                        1
                                                                                                                    ],
                                                                                                                    chCol.seq -
                                                                                                                      1,
                                                                                                                    $$a
                                                                                                                      .slice(
                                                                                                                        0,
                                                                                                                        $$i
                                                                                                                      )
                                                                                                                      .concat(
                                                                                                                        $$a.slice(
                                                                                                                          $$i +
                                                                                                                            1
                                                                                                                        )
                                                                                                                      )
                                                                                                                  )
                                                                                                              }
                                                                                                            } else {
                                                                                                              _vm.$set(
                                                                                                                q
                                                                                                                  .answer[
                                                                                                                  chRow.seq -
                                                                                                                    1
                                                                                                                ],
                                                                                                                chCol.seq -
                                                                                                                  1,
                                                                                                                $$c
                                                                                                              )
                                                                                                            }
                                                                                                          },
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.preventDefault()
                                                                                                            return _vm.changeAnswer(
                                                                                                              q
                                                                                                            )
                                                                                                          },
                                                                                                        ],
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                                _c(
                                                                                                  "label",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        for:
                                                                                                          "checkboxType-" +
                                                                                                          chRow.id +
                                                                                                          "-" +
                                                                                                          chCol.id,
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "라벨" +
                                                                                                        _vm._s(
                                                                                                          chCol.id
                                                                                                        )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    : _vm._e(),
                                                                                ]
                                                                              }
                                                                            ),
                                                                          ],
                                                                          2
                                                                        ),
                                                                      ]
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                            q.type === 8
                                              ? [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "scale-type",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "scale-items",
                                                        },
                                                        [
                                                          _vm._l(
                                                            Array.from(
                                                              {
                                                                length:
                                                                  q.scaleLevel,
                                                              },
                                                              function (v, i) {
                                                                return (
                                                                  i + q.minValue
                                                                )
                                                              }
                                                            ),
                                                            function (point) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    key:
                                                                      q.type +
                                                                      "_" +
                                                                      q.id +
                                                                      "_" +
                                                                      point,
                                                                    staticClass:
                                                                      "scale-radio-type",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                q.answer,
                                                                              expression:
                                                                                "q.answer",
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          id:
                                                                            "scaleType" +
                                                                            (q.type +
                                                                              "_" +
                                                                              q.id +
                                                                              "_" +
                                                                              point),
                                                                          type: "radio",
                                                                          name:
                                                                            "scaleType" +
                                                                            (q.type +
                                                                              "_" +
                                                                              q.id),
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              point,
                                                                            checked:
                                                                              _vm._q(
                                                                                q.answer,
                                                                                point
                                                                              ),
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            [
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  q,
                                                                                  "answer",
                                                                                  point
                                                                                )
                                                                              },
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.changeAnswer(
                                                                                  q
                                                                                )
                                                                              },
                                                                            ],
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for:
                                                                            "scaleType" +
                                                                            (q.type +
                                                                              "_" +
                                                                              q.id +
                                                                              "_" +
                                                                              point),
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            point
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "guide-txt",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "txt-left",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(q.minDesc)
                                                          ),
                                                        ]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(q.medDesc)
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "txt-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(q.maxDesc)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                            q.type === 100 ? void 0 : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.getErrorMessage(q.id),
                                  expression: "getErrorMessage(q.id)",
                                },
                              ],
                              key: "invalid" + q.id,
                              staticClass: "message-box",
                            },
                            [
                              _c("p", { staticClass: "essential-message" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.getErrorMessage(q.id)) + " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm.elements && _vm.elements.length
                    ? _c("div", { staticClass: "btn-wrap" }, [
                        _c(
                          "button",
                          {
                            class:
                              _vm.currPageIdx > 0 ? "btn-sendIn" : "btn-before",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.prevPage($event)
                              },
                            },
                          },
                          [_vm._v(" 이전 ")]
                        ),
                        _vm.pages.length &&
                        _vm.currPageIdx < _vm.pages.length - 1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-sendIn",
                                attrs: { disabled: _vm.isProcessing },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.nextPage($event)
                                  },
                                },
                              },
                              [_vm._v(" 다음 ")]
                            )
                          : _vm._e(),
                        _vm.pages.length &&
                        _vm.currPageIdx === _vm.pages.length - 1
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-sendIn",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.nextPage($event)
                                  },
                                },
                              },
                              [_vm._v(" 제출하기 ")]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.isRewardPage
              ? _c("reward-choice", {
                  attrs: { id: _vm.id },
                  on: { choice: _vm.rewardChoiceResult },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
      _c("reward-completed-dialog", {
        attrs: { options: _vm.rewardCompletedDialogProps },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }