<template>
  <div
    v-if="isShow"
    class="application-wrap"
    style="min-height: 500px; width: 508px"
  >
    <button
      class="btn-close"
      @click.stop="close(false)"
    >
      닫기
    </button>
    <h1>개인정보 수집·이용 안내</h1>
    <p class="s-title">
      설문조사 참여자 보상 지급을 위해 개인정보를 입력해 주세요.
    </p>

    <p class="mgt28 b-title">
      [필수] 설문 참여자 개인정보
    </p>
    <div class="aw-tb">
      <table>
        <colgroup>
          <col style="width:94px ">
          <col style="width:auto ">
        </colgroup>
        <tr>
          <th>이름</th>
          <td>
            <div class="text-wrap">
              <input
                v-model="inputName"
                type="text"
                placeholder="이름 입력"
              >
            </div>
          </td>
        </tr>
        <tr>
          <th>휴대전화번호</th>
          <td>
            <div class="text-wrap">
              <input
                v-model="inputPhoneNum"
                type="text"
                placeholder="‘-’를 제외하고 숫자만 입력"
                maxlength="11"
              >
            </div>
          </td>
        </tr>
      </table>
    </div>

    <p class="txt-red f13">
      ※ 제출하신 개인정보는 수정이 불가능하며, 설문 참여 보상은 입력하신 휴대전화번호로<br>즉시 발송됩니다.
    </p>

    <p class="mgt28 b-title mgb16">
      [필수] 개인정보 수집 및 이용 동의
    </p>
    <div class="agree-box event-type">
      <ol>
        <li><span>1.</span>수집·이용 목적 : 닥터인포 설문조사 참여자 보상 지급</li>
        <li><span>2.</span>수집·이용 항목 : 설문 참여자의 이름, 휴대전화번호</li>
        <li><span>3.</span>보유 및 이용 기간 : 보상지급일로부터 3개월까지</li>
        <li><span>4.</span>개인정보 수집 및 이용 동의에 거부할 수 있으나, 거부할 경우 보상이<br>지급되지 않습니다.</li>
      </ol>
    </div>

    <div class="checkbox-wrap mgt12">
      <input
        id="isPrivacyConfirm"
        v-model="isPrivacyConfirm"
        type="checkbox"
        class="application-type"
        name="agreeType"
      >
      <label
        for="isPrivacyConfirm"
        class="f16"
      >개인정보 수집 및 이용에 동의합니다.</label>
    </div>

    <div class="btn-wrap">
      <button
        class="btn-application"
        @click.stop="submit"
      >
        확인
      </button>
    </div>

    <alert-dialog :options="alertProps" />
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      inputName: null,
      inputPhoneNum: null,
      isPrivacyConfirm: false,
    }
  },

  methods: {
    submit() {
      if (!this.inputName) {
        this.showAlertDialog('이름을 입력해 주세요.')
        return
      }
      if (!this.inputPhoneNum) {
        this.showAlertDialog('휴대전화번호를 입력해 주세요.')
        return
      }
      const phoneNumberRegex = /^010\d{8}$/
      if (!this.inputPhoneNum.match(phoneNumberRegex)) {
        this.showAlertDialog('입력하신 휴대전화번호가 올바르지 않습니다.')
        return
      }
      if (!this.isPrivacyConfirm) {
        this.showAlertDialog('개인정보 이용 및 수집에 동의해 주세요.')
        return
      }

      this.close(true)
    },

    close(isConfirm) {
      this.$emit('close', isConfirm ? {
        verify: false,
        name: this.inputName,
        phoneNum: this.inputPhoneNum
      } : null)
    }
  }
}
</script>
