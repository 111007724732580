<template>
  <transition name="fade">
    <div
      v-if="options.show"
      class="layer-popup-alert survey-progress-type survey-completed"
      style="z-index: 1000; position: fixed;"
    >
      <p class="txt">
        <template v-for="(msg, index) in splitMessages">
          {{ msg }} <br :key="index">
        </template>
      </p>
      <p
        class="img-banner"
        style="cursor: pointer"
      ><img
        :src="options.bannerPath"
        @click.stop="clickBanner"
      ></p>

      <div class="flex-c">
        <button
          class="btn-ok"
          @click="submit(true)"
        >
          확인
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  computed: {
    splitMessages() {
      return this.options.message.split('\n')
    },
  },

  methods: {
    clickBanner() {
      if (this.options && this.options.submitCallback) {
        this.options.submitCallback(true)
      }
      if (this.options && this.options.bannerContentId) {
        if (this.options.contentType === 2) this.$router.push({ name: 'app-medical-trend-main', query: { id: this.options.bannerContentId, entry: 182 } })
        else if (this.options.contentType === 7) this.$router.push({ name: 'app-event-main', query: { id: this.options.bannerContentId, entry: 192 } })
      }
    },

    submit(isOk) {
      if (this.options && this.options.submitCallback) {
        this.options.submitCallback(isOk)
      }
      this.options.show = false
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
