<template>
  <!-- 설문 조사 -->
  <article class="contents">
    <div class="survey-area">
      <div
        id="surveyGuide"
        class="tab-nav common-type"
      >
        <ul>
          <!-- <li :class="{ 'on': isFinish === null }">
            <a @click.prevent="changeTab('all')">전체</a>
          </li> -->
          <li :class="{ 'on': isFinish === false }">
            <a @click.prevent="changeTab('ing')">진행중</a>
          </li>
          <li :class="{ 'on': isFinish === true }">
            <a @click.prevent="changeTab('fin')">종료됨</a>
          </li>
        </ul>
      </div>

      <div class="survey-search">
        <p class="txt ing-type">
          총 <span>{{ joinAbleRecord | $numberFormatter }}</span> 건의 {{ isFinish ? '설문 조사가 진행되었습니다.' : '설문 조사에 참여하실 수 있습니다.' }}
        </p>

        <div class="select-wrap">
          <select
            v-model="surveyType"
            @change="changeSurveyType"
          >
            <option value="0">
              전체보기
            </option>
            <option value="1">
              일반 설문
            </option>
            <option value="2">
              Weekly choice
            </option>
          </select>
        </div>
      </div>
      <div class="tab-contents">
        <div>
          <!-- 전체 -->
          <!-- 썸네일 리스트 -->
          <div class="surThumbnail-wrap">
            <div class="surThumbnail-list">
              <div
                v-for="(item, index) in surveys"
                :key="index"
                class="surThumbnail-item"
                :class="{ 'end-type': item.isFinish }"
                @click.prevent="clickSurvey(item)"
              >
                <ul>
                  <li
                    class="s-title"
                    :style="{ 'color': (item.isFinish ? '#555555' : '#6E93EF'), 'font-size': '15px' }"
                  >
                    {{ item.summary ? `[${item.summary}]` : '' }}
                    <span
                      v-show="item.isImminent && (!item.isFinish && !item.isNonTarget && !item.isAnswered && !item.isAnsweredDupJoin)"
                      class="label"
                      style="background: #3267E9"
                    >마감임박</span>
                    <span
                      v-show="item.surveyType === 1 && (isLogin || join === false) && (item.isNonTarget || item.isAnswered || item.isAnsweredDupJoin)"
                      class="label"
                      style="background: #3267E9"
                    >{{ item.isNonTarget ? '참여대상아님' : '참여완료' }}</span>
                    <span
                      v-show="item.surveyType === 2 && item.isAnswered"
                      class="label"
                      style="background: #00B7FF; cursor: pointer;"
                    >결과보기</span>
                  </li>
                  <li
                    class="title"
                    style="font-size: 18px;"
                  >
                    {{ item.title }}
                  </li>
                  <li class="date">
                    {{ item.startDate | $dateFormatter }}
                    ~
                    {{ item.endDate | $dateFormatter }}
                    <span>{{ item.targetPeopleNum > 0 && item.isAutoCloseDisp ? `(${ item.targetPeopleNum }명 선착순 마감)` : '' }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <pagination
            :page="currentPage"
            :records="totalRecord"
            :per="8"
            @paging="pageUpdate"
          />
        </div>
      </div>
    </div>
    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
    <survey-view
      :id="surveyId"
      :entry="surveyEntry"
      :show-modal="showSurvey"
      @close="closeViewers"
    />
    <weekly-choice-view
      :id="weeklyChoiceId"
      :show-modal="showWeeklyChoice"
      @close="closeViewers"
    />
  </article>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { isUserLoggedIn } from '@/auth/utils'
import { setStatistics } from '@/common/statistics/service'
import { errorFormatter } from '@/libs/utils/filters'
import { getUserLoginInfo } from '@/common/userInfo/service'
import Pagination from '@/components/controls/Pagination.vue'
import SurveyView from './SurveyView.vue'
import WeeklyChoiceView from './WeeklyChoiceView.vue'

export default {
  components: {
    Pagination,
    SurveyView,
    WeeklyChoiceView,
  },

  data() {
    return {
      isLogin: false,
      surveys: null,
      surveyType: 0,
      isFinish: false,
      currentPage: 1,
      totalRecord: 0,
      joinAbleRecord: 0,
      surveyId: null,
      surveyEntry: null,
      showSurvey: false,
      weeklyChoiceId: null,
      showWeeklyChoice: false,
      delayExec: false,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
      params: 'util/getParams',
      medicalDept: 'infoData/getMedicalDept',
      shaYoyangNum: 'infoData/getShaYoyangNum',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
      infoSetting: 'infoData/getInfoSetting',
      refreshTokenSetting: 'infoData/getRefreshTokenSetting',
      join: 'util/getJoin', // 회원여부
    })
  },
  watch: {
    params() {
      this.mountedSurvey()
    },

    $route() {
      this.mountedSurvey()
    },

    userName(val) {
      if (val === null) this.isLogin = false
      else this.isLogin = true
    },

    infoSetting() {
      if (this.delayExec && this.infoSetting && this.refreshTokenSetting) {
        this.mountedSurvey()
        this.delayExec = false
      }
    },

    refreshTokenSetting() {
      if (this.delayExec && this.infoSetting && this.refreshTokenSetting) {
        this.mountedSurvey()
        this.delayExec = false
      }
    },
  },

  created() {
    if (this.userName !== null) this.isLogin = true
  },

  async mounted() {
    if (this.infoSetting && this.refreshTokenSetting) {
      this.mountedSurvey()
    } else {
      this.delayExec = true
    }
  },

  methods: {
    changeTab(tab) {
      this.isFinish = tab === 'fin'

      this.fetchSurveys()
    },

    changeSurveyType() {
      this.fetchSurveys()
    },

    pageUpdate(page) {
      this.fetchSurveys(page)
    },

    async checkSurveyAuth(params) {
      this.isLogin = await isUserLoggedIn()

      if (this.isLogin === false && this.join) {
        // 회원이면서, 비 로그인 상태인 경우 로그인 페이지로 이동
        this.$router.push({
          name: 'login-main',
          params: {
            id: params.id, surveyType: params.surveyType, entry: params.entry, backContinue: false
          },
          query: { redirect: 'app-survey-main' }
        }).catch(() => {})

        return false
      }
      return true
    },

    async checkWChoiceAuth(params) {
      this.isLogin = await isUserLoggedIn()

      if (!this.isLogin) {
        this.$router.push({
          name: 'login-main',
          params: {
            id: params.id, surveyType: params.surveyType, entry: params.entry, backContinue: false
          },
          query: { redirect: 'app-survey-main' }
        }).catch(() => {})

        return false
      }
      return true
    },

    async mountedSurvey() {
      // 설문 조사 목록 조회
      this.fetchSurveys()

      // 회원여부 등 조회
      if (this.join === null) await getUserLoginInfo()

      // 호출 설문 존재 시
      // 파라메터는 queryString or params 둘다 지원)
      let target = null
      if (this.params && this.params.route === this.$route.name) {
        target = this.params
      } else {
        target = this.$route.params.id ? this.$route.params : this.$route.query
      }

      if (target && target.id) {
        if (Number(target.surveyType) === 1) {
          // 회원여부 등 체크
          const checkLogin = await this.checkSurveyAuth(target)
          if (checkLogin === false) return

          const item = await this.fetchSurveyById(target.id)
          if (item) {
            this.openSurvey(item, target.entry)
          }
        } else {
          // Weekly Choice
          const checkLogin = await this.checkWChoiceAuth(target)
          if (checkLogin === false) return

          this.openSurvey(target)
        }
      }
    },

    async clickSurvey(item) {
      const target = item
      const subPageEntry = 157 // 157: 서브페이지 진입

      // 리스트 클릭 이벤트에서 로그인 리다이렉트가 일어나는 경우,
      // 진입 경로를 서브페이지 클릭으로 설정하기 위해 item.entry에 삽입
      target.entry = subPageEntry

      if (Number(item.surveyType) === 1) {
        const checkLogin = await this.checkSurveyAuth(item)
        if (checkLogin === false) return
      } else {
        const checkLogin = await this.checkWChoiceAuth(item)
        if (checkLogin === false) return
      }

      this.openSurvey(item, subPageEntry)
    },

    openSurvey(item, entry = 158) {
      this.closeViewers(false)

      if (item.surveyType === 1) {
        // 일반 설문
        if (item.isFinish) {
          this.showAlertDialog('종료된 설문입니다.')
          return
        }
        if (item.isNonTarget) {
          this.showAlertDialog('설문조사 참여 대상에 해당되지 않아 참여가 불가합니다.')
          return
        }
        if (item.isAnswered) {
          this.showAlertDialog('해당 설문에 이미 참여하셨습니다.')
          return
        }
        if (item.isAnsweredDupJoin) {
          this.showAlertDialog('해당 설문에 이미 참여하셨습니다.\n(*기관 대상 설문으로 소속 의원 설문 참여완료로 확인)')
          return
        }

        const surveyId = item.id
        // 설문조사 진입 통계
        if (!this.surveyEntry) this.surveyEntry = Number(entry)
        setStatistics(this.surveyEntry, surveyId)

        this.surveyId = surveyId
        // 설문조사 오픈
        this.showSurvey = true
      } else if (item.surveyType === 2) {
        // Weekly Choice
        this.weeklyChoiceId = item.id
        this.showWeeklyChoice = true
      }

      if (this.params) this.$store.commit('util/setParams', null)
    },

    closeViewers(isRefresh) {
      this.surveyId = null
      this.showSurvey = false
      this.weeklyChoiceId = null
      this.showWeeklyChoice = false

      if (isRefresh) {
        this.fetchSurveys()
      }
    },

    async fetchSurveyById(id) {
      try {
        const item = await axios.post(`/fu/survey/${id}`, {
          medicalDept: this.medicalDept,
          shaYoyangNum: this.shaYoyangNum,
          shaEnLicenseNum: this.shaEnLicenseNum,
        })
        return item.data
      } catch (err) {
        if (err.response && err.response.status === 404) {
          this.showAlertDialog('현재 진행할 수 없는 설문입니다.')
        } else {
          this.showAlertDialog(errorFormatter(err, '설문 데이터를 불러오는 과정에서 오류가 발생하였습니다.'))
        }
        return null
      }
    },

    fetchSurveys(page = 1) {
      axios.post('/fu/survey/list', {
        surveyType: this.surveyType > 0 ? this.surveyType : null,
        isFinish: this.isFinish,
        medicalDept: this.medicalDept,
        shaYoyangNum: this.shaYoyangNum,
        shaEnLicenseNum: this.shaEnLicenseNum,
        pagination: {
          number: page,
          count: 8,
        },
      })
        .then(rs => {
          const { items, totalRecord, joinAbleRecord } = rs.data

          this.surveys = items
          this.totalRecord = totalRecord
          this.joinAbleRecord = joinAbleRecord
          this.currentPage = page
        })
        .catch(err => {
          this.showAlertDialog(errorFormatter(err, '설문조사 조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.'))
        })
    },
  },
}
</script>
