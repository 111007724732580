var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "div",
        {
          staticClass: "application-wrap",
          staticStyle: { "min-height": "500px", width: "508px" },
        },
        [
          _c(
            "button",
            {
              staticClass: "btn-close",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.close(false)
                },
              },
            },
            [_vm._v(" 닫기 ")]
          ),
          _c("h1", [_vm._v("개인정보 수집·이용 안내")]),
          _c("p", { staticClass: "s-title" }, [
            _vm._v(
              " 설문조사 참여자 보상 지급을 위해 개인정보를 입력해 주세요. "
            ),
          ]),
          _c("p", { staticClass: "mgt28 b-title" }, [
            _vm._v(" [필수] 설문 참여자 개인정보 "),
          ]),
          _c("div", { staticClass: "aw-tb" }, [
            _c("table", [
              _vm._m(0),
              _c("tr", [
                _c("th", [_vm._v("이름")]),
                _c("td", [
                  _c("div", { staticClass: "text-wrap" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputName,
                          expression: "inputName",
                        },
                      ],
                      attrs: { type: "text", placeholder: "이름 입력" },
                      domProps: { value: _vm.inputName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.inputName = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v("휴대전화번호")]),
                _c("td", [
                  _c("div", { staticClass: "text-wrap" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputPhoneNum,
                          expression: "inputPhoneNum",
                        },
                      ],
                      attrs: {
                        type: "text",
                        placeholder: "‘-’를 제외하고 숫자만 입력",
                        maxlength: "11",
                      },
                      domProps: { value: _vm.inputPhoneNum },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.inputPhoneNum = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._m(1),
          _c("p", { staticClass: "mgt28 b-title mgb16" }, [
            _vm._v(" [필수] 개인정보 수집 및 이용 동의 "),
          ]),
          _vm._m(2),
          _c("div", { staticClass: "checkbox-wrap mgt12" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isPrivacyConfirm,
                  expression: "isPrivacyConfirm",
                },
              ],
              staticClass: "application-type",
              attrs: {
                id: "isPrivacyConfirm",
                type: "checkbox",
                name: "agreeType",
              },
              domProps: {
                checked: Array.isArray(_vm.isPrivacyConfirm)
                  ? _vm._i(_vm.isPrivacyConfirm, null) > -1
                  : _vm.isPrivacyConfirm,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.isPrivacyConfirm,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isPrivacyConfirm = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isPrivacyConfirm = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isPrivacyConfirm = $$c
                  }
                },
              },
            }),
            _c(
              "label",
              { staticClass: "f16", attrs: { for: "isPrivacyConfirm" } },
              [_vm._v("개인정보 수집 및 이용에 동의합니다.")]
            ),
          ]),
          _c("div", { staticClass: "btn-wrap" }, [
            _c(
              "button",
              {
                staticClass: "btn-application",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.submit($event)
                  },
                },
              },
              [_vm._v(" 확인 ")]
            ),
          ]),
          _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "94px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "txt-red f13" }, [
      _vm._v(
        " ※ 제출하신 개인정보는 수정이 불가능하며, 설문 참여 보상은 입력하신 휴대전화번호로"
      ),
      _c("br"),
      _vm._v("즉시 발송됩니다. "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "agree-box event-type" }, [
      _c("ol", [
        _c("li", [
          _c("span", [_vm._v("1.")]),
          _vm._v("수집·이용 목적 : 닥터인포 설문조사 참여자 보상 지급"),
        ]),
        _c("li", [
          _c("span", [_vm._v("2.")]),
          _vm._v("수집·이용 항목 : 설문 참여자의 이름, 휴대전화번호"),
        ]),
        _c("li", [
          _c("span", [_vm._v("3.")]),
          _vm._v("보유 및 이용 기간 : 보상지급일로부터 3개월까지"),
        ]),
        _c("li", [
          _c("span", [_vm._v("4.")]),
          _vm._v(
            "개인정보 수집 및 이용 동의에 거부할 수 있으나, 거부할 경우 보상이"
          ),
          _c("br"),
          _vm._v("지급되지 않습니다."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }