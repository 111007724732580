<template>
  <div class="survey-reward-layer-area">
    <div
      class="csp-contents"
      style="padding-bottom: 0px"
    >
      <p class="s-txt">
        닥터인포 설문조사에 참여해 주셔서 감사합니다.
      </p>
      <p class="b-txt">
        참여 보상을 선택해 주세요<br>
        <span>(포인트 or 기프티콘)</span>
      </p>
      <p class="txt-blue">*닥터인포 포인트는 회원 전용 혜택으로,<br>
        적립한 포인트는 포인트몰에서 다양한 상품권으로 교환할 수 있습니다.
      </p>
      <div class="items-div2">
        <div
          class="item-point"
          @click.stop="pointRewardPreProcess"
        >
          <dl>
            <dt>닥터인포</dt>
            <dd>포인트 받기<i class="icon-arrow" /></dd>
          </dl>
        </div>

        <div
          class="item-gifticon"
          @click.stop="giftRewardPreProcess"
        >
          <img :src="goodsImgUrl">
        </div>
      </div>

      <!-- 개인정보 수집 및 이용 동의 팝업 -->
      <reward-privacy-confirm
        :is-show="showPrivacyConfirmPopup"
        @close="closePrivacyConfirmPopup"
      />

      <alert-dialog :options="alertProps" />
      <confirm-dialog :options="confirmProps" />
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { setException } from '@/common/error/log'
import { errorFormatter } from '@/libs/utils/filters'
import { getUserLoginInfo } from '@/common/userInfo/service'
import RewardPrivacyConfirm from './RewardPrivacyConfirm.vue'

export default {
  components: {
    RewardPrivacyConfirm,
  },

  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isLogin: false,
      goodsImgUrl: null,
      showPrivacyConfirmPopup: false,
    }
  },

  computed: {
    ...mapGetters({
      userName: 'account/getName',
      phoneNum: 'account/getPhoneNum',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
      join: 'util/getJoin', // 회원여부
      verify: 'util/getVerify', // 본인인증여부
    })
  },

  watch: {
    userName(val) {
      if (val === null) this.isLogin = false
      else this.isLogin = true
    },

    shaEnLicenseNum() {
      if (this.join === null) getUserLoginInfo()
    },

    id() {
      if (this.id) this.fetchRewardGiftImage(this.id)
    }
  },

  created() {
    if (this.userName !== null) this.isLogin = true

    // 회원여부, 본인인증여부 조회
    if (this.join === null) getUserLoginInfo()

    // 기프티콘 등록 상품 이미지 경로 조회
    if (this.id) this.fetchRewardGiftImage(this.id)
  },

  methods: {
    async pointRewardPreProcess() {
      if (this.join === false) {
        this.showConfirmDialog('포인트는 회원전용 혜택입니다.\n의사랑 계정 연결을 통해 간편 회원가입을 진행하시겠습니까?', confirm => {
          if (confirm) {
            this.$emit('choice', {
              type: 1, // 포인트
              isJoin: false,
            })
          }
        })
      } else {
        this.$emit('choice', {
          type: 1, // 포인트
          isJoin: true,
        })
      }
    },

    giftRewardPreProcess() {
      if (this.verify && this.userName && this.phoneNum) {
        // 본인인증한 회원인 경우 기프티콘 즉시 발송
        this.$emit('choice', {
          type: 2, // 기프티콘
          privacy: {
            verify: true,
            name: this.userName,
            phoneNum: this.phoneNum
          }
        })
      } else {
        // 개인정보 수집 및 이용동의 팝업 호출
        this.showPrivacyConfirmPopup = true
      }
    },

    async closePrivacyConfirmPopup(privacy) {
      if (privacy) {
        const result = await this.checkDupPhoneNumber(privacy)
        if (!result) return

        this.$emit('choice', { type: 2, privacy })

        this.showPrivacyConfirmPopup = false
      } else {
        this.showPrivacyConfirmPopup = false
      }
    },

    fetchRewardGiftImage(id) {
      axios.get(`/fu/survey/reward-gift-image/${id}`)
        .then(rs => {
          this.goodsImgUrl = rs.data.goodsImgUrl
        })
        .catch(err => {
          this.showAlertDialog('등록된 기프티콘 상품 이미지를 불러오는데 실패하였습니다.\n관리자에게 문의해 주세요.')
          setException(err, 'RewardChoice.vue')
        })
    },

    async checkDupPhoneNumber(privacy) {
      try {
        await axios.post(`/fu/survey/check-dup-phone-number/${this.id}`, {
          userHash: this.shaEnLicenseNum,
          userName: privacy.name,
          phoneNumber: privacy.phoneNum,
        })
        return true
      } catch (err) {
        this.showAlertDialog(errorFormatter(err, '입력하신 핸드폰번호 지급 확인 과정에서 오류가 발생하였습니다.\n재시도하여도 문제가 계속될 경우 관리자에게 문의하여 주시기 바랍니다.'))
        return false
      }
    },
  },
}
</script>
